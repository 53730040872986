export default [
  {
    path: '/configuraciones',
    name: 'settings',
    component: () => import('@/views/settings/AppSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
