export default [
  {
    path: '/proveedor/:supplierId/sucursal/:branchId',
    name: 'branch',
    component: () => import('@/views/branches/Branch.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/regsitrar-sucursal/:id',
    name: 'registerBranch',
    component: () => import('@/views/branches/RegisterBranch.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/editar-sucursal/:supplierId/:branchId',
    name: 'editBranch',
    component: () => import('@/views/branches/EditBranch.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
