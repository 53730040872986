import axios from '@axios'

const state = () => ({
  incomes: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
})

const getters = {
  incomes(state) {
    return state.incomes.data
  },
  incomePagination(state) {
    return state.incomes.meta.pagination
  },
}

const mutations = {
  SET_INCOMES(state, payload) {
    state.incomes = payload
  },
  CHANGE_PAGINATION(state, { field, value }) {
    state.incomes.meta.pagination[field] = value
  },
}

const actions = {
  fetchIncomes(
    context,
    { projectId = null, pagination = { page: 1, per_page: 10, all: true } },
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/incomes', {
          params: {
            by_project: projectId,
            meta: {
              pagination,
            },
          },
        })
        .then(response => {
          context.commit('SET_INCOMES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchIncome(context, incomeId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/incomes/${incomeId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  addIncome(context, income) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/incomes', {
          income,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  editIncome(context, income) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/incomes/${income.id}`, {
          income,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deleteIncome(_, incomeId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/incomes/${incomeId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
