export default [
  {
    path: '/subasta',
    component: () => import('@/views/pricing/Pricing.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'primera-ronda/:quoteId',
        name: 'firstRound',
        component: () => import('@/views/pricing/FirstRound.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'segunda-ronda',
        name: 'secondRound',
        component: () => import('@/views/pricing/SecondRound.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/precotizacion/:quoteId',
    name: 'prequote',
    component: () => import('@/views/pricing/Prequote.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cotizacion/:id',
    name: 'clientQuote',
    component: () => import('@/views/pricing/ClientQuote.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cotizacion/:id/desglose',
    name: 'clientQuoteMetadata',
    component: () => import('@/views/pricing/ClientQuoteMetadata.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cotizacion/:quoteId/facturas',
    name: 'quoteInvoices',
    component: () => import('@/views/invoices/Invoices.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cotizaciones/:projectId',
    name: 'quotes',
    component: () => import('@/views/pricing/MyQuotes.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/nueva-cotizacion/:projectId',
    name: 'newQuotes',
    component: () => import('@/views/pricing/NewQuote.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/editar-cotizacion/:quoteId',
    name: 'editQuote',
    component: () => import('@/views/pricing/EditQuote.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/agregar-productos/:quoteId',
    name: 'addProductsToQuote',
    component: () => import('@/views/pos/POS.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cotizar-productos',
    name: 'quoteProducts',
    component: () => import('@/views/pos/POS.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
      requiresAuth: false,
    },
  },
  {
    path: '/checkout/:quoteId',
    name: 'checkout',
    component: () => import('@/views/invoice/Checkout.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/datos-bancarios/:quoteId',
    name: 'bankAccountData',
    component: () => import('@/views/invoice/BankAccount.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/subasta-en-progreso/:quoteId',
    name: 'auctionInProgress',
    component: () => import('@/views/AuctionInProgress.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
