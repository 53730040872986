import axios from '@axios'
import Vue from 'vue'

const state = () => ({
  shippings: {
    data: [],
    meta: {},
  },
})

const getters = {
  shippings(state) {
    return state.shippings.data
  },
  shipping(state) {
    return state.shipping
  },
  newProviderFiles(state) {
    return state.newProviderFiles
  },
}

const mutations = {
  SET_SHIPPINGS(state, payload) {
    payload.data = payload.data.reverse()
    state.shippings = payload
  },
  SET_SHIPPINGS_AFTER_POST(state, payload) {
    state.shippings.data = payload
  },
  SET_SHIPPING(state, payload) {
    state.shipping = payload
  },
  ADD_SHIPPING(state, payload) {
    state.shippings.data.push(payload)
  },
  REMOVE_SHIPPING(state, { index }) {
    state.shippings.data.splice(index, 1)
  },
  SET_SHIPPING_FIELD(state, { item, field, value }) {
    Vue.set(item, field, value)
  },
}

const actions = {
  fetchShippings(context, { byBranch = null }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/shippings', {
          params: {
            by_branch: byBranch,
            meta: {
              pagination: {
                page: 1,
                per_page: 10,
                all: true,
              },
            },
          },
        })
        .then(response => {
          context.commit('SET_SHIPPINGS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  postShippings(context, { branchId, shippings }) {
    // shippings must be an array of shippings : [{min:'', max:'', price:''}]
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/shippings', {
          shipping: {
            supplier_branch_id: branchId,
            shipping_records: shippings,
          },
        })
        .then(response => {
          context.commit('SET_SHIPPINGS_AFTER_POST', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchShipping(context, supplierId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/shippings/${supplierId}`)
        .then(response => {
          context.commit('SET_SHIPPING', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  editShipping(context, shipping) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/shippings/${shipping.id}`, {
          shipping,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
