import { AbilityBuilder, Ability } from '@casl/ability'
import helpers from '@/helpers'

export default function projectAbility(project) {
  const { can, build } = new AbilityBuilder(Ability)
  if (helpers.validZone(project?.address?.state, project?.address?.formatted_address)) {
    can('navigate', 'quotes')
  }
  return build()
}
