/* eslint-disable global-require */
import axios from '@axios'

const state = () => ({
  products: {
    meta: {
      pagination: {
        page: 1,
        per_page: 25,
        total_objects: 0,
      },
    },
    data: [],
  },
  stockableProducts: {
    meta: {
      pagination: {
        page: 1,
        per_page: 25,
        total_objects: 0,
      },
    },
    data: [],
  },
  cardMode: null,
  selectedCategory: null,
})

const getters = {
  products(state) {
    return state.products.data
  },
  productsPagination(state) {
    return state.products.meta.pagination
  },
  stockableProducts(state) {
    return state.stockableProducts.data
  },
  stockableProductsPagination(state) {
    return state.stockableProducts.meta.pagination
  },
  cardMode(state) {
    if (typeof state.cardMode === 'boolean') {
      return state.cardMode
    }
    if (typeof JSON.parse(localStorage.getItem('cardMode')) === 'boolean') {
      return JSON.parse(localStorage.getItem('cardMode'))
    }
    return true
  },
  selectedCategory(state) {
    return state.selectedCategory
  },
  productsGroupedByCategory(state) {
    const categories = []
    state.products.data.forEach(product => {
      const index = categories.findIndex(
        category => category.name === product.category,
      )
      if (index > -1) {
        categories[index].products.push(product)
      } else {
        categories.push({
          name: product.category,
          products: [product],
        })
      }
    })
    return categories
  },
}

const mutations = {
  SET_PRODUCTS(state, payload) {
    state.products = payload
  },
  CHANGE_PRODUCTS_PAGINATION(state, { field, value }) {
    state.products.meta.pagination[field] = value
  },
  CLEAR_PRODUCTS(state) {
    state.products = {
      meta: {
        pagination: {
          page: 1,
          per_page: 25,
          total_objects: 0,
        },
      },
      data: [],
    }
  },
  SET_STOCKABLE_PRODUCTS(state, payload) {
    state.stockableProducts = payload
  },
  CHANGE_STOCKABLE_PAGINATION(state, { field, value }) {
    state.stockableProducts.meta.pagination[field] = value
  },
  SET_CARD_MODE(state, payload) {
    state.cardMode = payload
    window.localStorage.setItem('cardMode', payload)
  },
  SET_SELECTED_CATEGORY(state, category) {
    state.selectedCategory = category
  },
}

const actions = {
  fetchProducts(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/product_catalogs', {
          params,
        })
        .then(response => {
          context.commit('SET_PRODUCTS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  addProduct(context, productCatalog) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/product_catalogs', {
          product_catalog: productCatalog,
        })
        .then(response => {
          context.dispatch('fetchProducts')
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchProduct(context, { productId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/product_catalogs/${productId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  editProduct(context, product) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/product_catalogs/${product.id}`, {
          product_catalog: product,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deactivateProduct(context, productId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/product_catalogs/${productId}/change_active_status`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  addStockableProduct(context, stockable) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/stockable_product_catalogs', { stockable })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deleteStockableProduct(context, stockableId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/stockable_product_catalogs/${stockableId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getStockableProducts(context, { params, stockable }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/v1/stockable_product_catalogs?by_stockable[id]=${stockable.id}&by_stockable[type]=${stockable.type}`,
          {
            params,
          },
        )
        .then(response => {
          context.commit('SET_STOCKABLE_PRODUCTS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  updateStockableProductPrice(context, { price_list, id }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/stockable_product_catalogs/${id}/update_price`, {
          stockable: {
            price_list,
          },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  updateStockableSpecialDelivery(context, { id, is_special_delivery, special_delivery_description }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/stockable_product_catalogs/${id}/update_special_delivery`, {
          stockable: {
            is_special_delivery,
            special_delivery_description,
          },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
/* eslint-enable global-require */
