export default [
  {
    path: '/categorias',
    name: 'categories',
    component: () => import('@/views/categories/CategoriesList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/nueva-categoria',
    name: 'new-category',
    component: () => import('@/views/categories/CategoriesNew.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/editar-categoria/:categoryId',
    name: 'editCategory',
    component: () => import('@/views/categories/CategoriesEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
