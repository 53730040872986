export default [
  {
    path: '/fletes/:branchId',
    name: 'shippings',
    component: () => import('@/views/shippings/Shippings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
