import axios from '@axios'

const state = () => ({
  categories: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
})

const getters = {
  categories(state) {
    return state.categories.data
  },
}

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },
}

const actions = {
  fetchCategories(
    context,
    params = {
      by_active_status: true,
    },
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/categories', {
          params: {
            ...params,
            meta: {
              pagination: {
                all: true,
              },
            },
          },
        })
        .then(response => {
          context.commit('SET_CATEGORIES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  addCategory(context, category) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/categories', {
          category,
        })
        .then(response => {
          context.dispatch('fetchCategories')
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchCategory(context, { categoryId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/categories/${categoryId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  editCategory(context, category) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/categories/${category.id}`, {
          category,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deactivateCategory(context, category) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/categories/${category.id}`, {
          category: { ...category, active_status: !category.active_status },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
