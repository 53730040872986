import axios from '@axios'

const state = () => ({
  suppliers: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
  selectedProvider: '',
  newProviderFiles: [],
  supplier: {},
})

const getters = {
  suppliers(state) {
    return state.suppliers.data
  },
  suppliersPagination(state) {
    return state.suppliers.meta.pagination
  },
  supplier(state) {
    return state.supplier
  },
  newProviderFiles(state) {
    return state.newProviderFiles
  },
}

const mutations = {
  ADD_FILE(state, file) {
    state.newProviderFiles.push(file)
  },
  SET_FILE(state, file) {
    state.newProviderFiles = []
    state.newProviderFiles.push(file)
  },
  CLEAR_FILES(state) {
    state.newProviderFiles = []
  },
  REMOVE_FILE(state, index) {
    state.newProviderFiles.splice(index, 1)
  },
  SET_SELECTED_SUPPLIER(state, name) {
    state.selectedProvider = name
  },
  SET_SUPPLIERS(state, payload) {
    state.suppliers = payload
  },
  SET_SUPPLIER(state, payload) {
    state.supplier = payload
  },
  CHANGE_SUPPLIERS_PAGINATION(state, { field, value }) {
    state.suppliers.meta.pagination[field] = value
  },
}

const actions = {
  fetchSuppliers(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/suppliers', {
          params,
        })
        .then(response => {
          context.commit('SET_SUPPLIERS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchSuppliersByApprovedStatus(_, { status, name }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/suppliers', {
          params: {
            by_name: name,
            by_approved_status: status,
            meta: {
              pagination: { all: true },
            },
          },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  addSupplier(context, supplier) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/suppliers', {
          supplier,
        })
        .then(response => {
          context.dispatch('fetchSuppliers')
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchSupplier(context, supplierId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/suppliers/${supplierId}`)
        .then(response => {
          context.commit('SET_SUPPLIER', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  editSupplier(context, supplier) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/suppliers/${supplier.id}`, {
          supplier,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  fetchSupplierDocuments(_, supplierId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/suppliers/${supplierId}/supplier_documents`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  registerSupplier(_, supplier) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/suppliers/register', {
          supplier,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  uploadSupplierDocument(_, { supplierId, documentId, attachment }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/suppliers/${supplierId}/supplier_documents/${documentId}`, {
          supplier_document: {
            document_attachment: attachment,
          },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  requestApproval(_, supplierId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/suppliers/${supplierId}/request_approval`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  approveSupplier(_, supplierId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/suppliers/${supplierId}/approve_request`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  rejectSupplier(_, supplierId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/suppliers/${supplierId}/deny_request`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  changeSupplierStatus(_, supplierId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/suppliers/${supplierId}/change_active_status`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
