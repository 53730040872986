const state = () => ({
  posItems: [],
})

const getters = {
  posItems(state) {
    return state.posItems
  },
}

const mutations = {
  ADD_ITEM_TO_POS(state, payload) {
    const obj = payload
    if (!obj.quantity > 0) obj.quantity = 0
    state.posItems.push(obj)
  },
  REMOVE_ITEM_FROM_POS(state, { index }) {
    state.posItems.splice(index, 1)
  },
  CHANGE_ITEM_QUANTITY(state, { item, value }) {
    item.quantity = value
  },
  CHANGE_ITEM_FIELD(state, { item, field, value }) {
    item[field] = value
  },
  CLEAR_POS_ITEMS(state) {
    state.posItems = []
  },
  SET_POS_ITEMS(state, payload) {
    state.posItems = payload
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
