export default [
  {
    path: '/reportes/:projectId',
    name: 'reports',
    component: () => import('@/views/reports/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      // breadcrumb: [
      //   {
      //     text: 'projects',
      //     to: {
      //       name: 'projects',
      //     },
      //   },
      //   {
      //     text: 'dashboard',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/reportes/ingresos-egresos/:projectId',
    name: 'incomesVsExpenses',
    component: () => import('@/views/reports/IncomesVsExpenses.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/reportes/egresos/:projectId',
    name: 'expensesReport',
    component: () => import('@/views/reports/ExpensesReport.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
