export default [
  {
    path: '/facturas/clientes',
    name: 'customerInvoices',
    component: () => import('@/views/invoices/CustomerInvoices.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/facturas/proveedores',
    name: 'supplierInvoices',
    component: () => import('@/views/invoices/SupplierInvoices.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
