import axios from '@axios'

const state = () => ({
  settings: {},
  loading: false,
})

const getters = {
  settings(state) {
    return state.settings
  },
}

const mutations = {
  loading: (state, payload) => {
    state.loading = payload
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload
  },
}

const actions = {
  getSettings(context, params) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true)
      axios
        .get('/v1/app_settings', {
          params,
        })
        .then(response => {
          context.commit('SET_SETTINGS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('loading', false)
        })
    })
  },

  editSettings(context, settings) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true)
      axios
        .put('/v1/app_settings', {
          app_setting: settings,
        })
        .then(response => {
          context.commit('SET_SETTINGS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('loading', false)
        })
    })
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
