import axios from '@axios'

const state = () => ({
  expenses: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
})

const getters = {
  expenses(state) {
    return state.expenses.data
  },
  expensesTotal(state) {
    return state.expenses.total
  },
  expensesPagination(state) {
    return state.expenses.meta.pagination
  },
}

const mutations = {
  SET_EXPENSES(state, payload) {
    state.expenses = payload
  },
  CHANGE_PAGINATION(state, { field, value }) {
    state.expenses.meta.pagination[field] = value
  },
}

const actions = {
  fetchExpenses(
    context,
    {
      projectId = null,
      groupId = null,
      dates,
      supplier,
      status,
      pagination = { page: 1, per_page: 10, all: true },
    },
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/expenses', {
          params: {
            by_project: projectId,
            by_grouped_expense: groupId,
            by_supplier: supplier,
            by_expense_status: status,
            by_expense_date: dates,
            meta: {
              pagination,
            },
          },
        })
        .then(response => {
          context.commit('SET_EXPENSES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  addExpense(context, expense) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/expenses', {
          expense,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  updateExpenseStatus(_, { expenseId, status }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/expenses/${expenseId}`, {
          expense_status: status,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  updateExpense(_, expense) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/expenses/${expense.id}`, {
          expense,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deleteExpense(_, expenseId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/expenses/${expenseId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchSuppliers(_, { projectId }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/expenses/supplier_list', {
          params: {
            by_project: projectId,
          },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
