export default [
  {
    path: '/pagos',
    name: 'payments',
    component: () => import('@/views/payments/Payments.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pago/:quoteId',
    name: 'payment',
    component: () => import('@/views/invoice/Checkout.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
