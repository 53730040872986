export default [
  {
    path: '/clientes',
    name: 'customers',
    component: () => import('@/views/customers/Customers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/nuevo-cliente',
    name: 'new-customer',
    component: () => import('@/views/customers/CustomerNew.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/editar-cliente/:customerId',
    name: 'editCustomer',
    component: () => import('@/views/customers/CustomerEdit.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
