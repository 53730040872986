import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import * as GmapVue from 'gmap-vue'
import BootstrapVue from 'bootstrap-vue'
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import VueCroppie from 'vue-croppie'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueApexCharts from 'vue-apexcharts'
import money from 'v-money'
import i18n from '@/libs/i18n'

import router from './router'
import store from './store'
import App from './App.vue'

// eslint-disable-next-line
import 'croppie/croppie.css'

import './filters'
import helpers from './helpers'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import './registerServiceWorker'

// register directive v-money and component <money>
Vue.use(money, { precision: 4 })

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
// Composition API
Vue.use(VueCompositionAPI)

const helpersPlugin = {
  install() {
    // Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
    Vue.prototype.$getBase64 = helpers.getBase64
  },
}

Vue.use(helpersPlugin)
// GMAP Vue
Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyBv_O5IdSiNQ_Sv-JAaDasZRvYDj0nW-rM',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    region: 'MX',

    // v: '3.26',
  },
  // autobindAllEvents: false,
  installComponents: true,
})

// Bootrstrap Vue
Vue.use(BootstrapVue)

Vue.use(vueFilterPrettyBytes)

Vue.use(VueCroppie)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
