import axios from '@axios'
import Vue from 'vue'
import { saveAs } from 'file-saver'

const state = () => ({
  quotes: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
  biddingQuotes: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
  quote: {
    address: {},
    quote_items_attributes: [],
  },
  supplierQuoteItems: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
  quotePayments: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
})

const getters = {
  quotes(state) {
    return state.quotes.data
  },
  quotesPagination(state) {
    return state.quotes.meta.pagination
  },
  quote(state) {
    return state.quote
  },
  quoteIncome(state) {
    return state.quote.income
  },
  biddingQuotes(state) {
    return state.biddingQuotes.data
  },
  supplierQuoteItems(state) {
    return state.supplierQuoteItems.data
  },
  quotePayments(state) {
    return state.quotePayments.data
  },
}

const mutations = {
  ADD_QUOTE(state, quote) {
    state.quotes.push(quote)
  },
  SET_QUOTES(state, payload) {
    state.quotes = payload
  },
  CHANGE_PAGINATION(state, { field, value }) {
    state.quotes.meta.pagination[field] = value
  },
  CLEAR_QUOTES(state) {
    state.quotes = {
      meta: {
        pagination: {
          page: 1,
          per_page: 10,
          total_objects: 0,
        },
      },
      data: [],
    }
  },
  SET_QUOTE(state, payload) {
    state.quote = payload
  },
  SET_QUOTE_FIELD(state, { field, value }) {
    Vue.set(state.quote, field, value)
  },
  SET_BIDDING_QUOTES(state, payload) {
    state.biddingQuotes = payload
  },
  SET_SUPPLIER_QUOTE_ITEMS(state, payload) {
    state.supplierQuoteItems = payload
  },
  SET_QUOTE_ITEMS(state, items) {
    state.quote.quote_items_attributes = items
  },
  SET_QUOTE_PAYMENTS(state, items) {
    state.quotePayments = items
  },
}

const actions = {
  fetchQuotes(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/quotes', {
          params,
        })
        .then(response => {
          context.commit('SET_QUOTES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  addQuote(context, quote) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/quotes', {
          quote,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchQuote(context, quoteId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/quotes/${quoteId}`)
        .then(response => {
          context.commit('SET_QUOTE', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchQuoteMetadata(context, quoteId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/quotes/${quoteId}/metadata`)
        .then(response => {
          context.commit('SET_QUOTE', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  editQuote(context, quote) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/quotes/${quote.id}`, {
          quote,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  publishQuote(context, quoteId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/quotes/${quoteId}/publish`, {
          is_published: true,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  buyoutQuote(context, quoteId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/quotes/${quoteId}/buyout`, {
          quote_status: 'buyout',
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  closeQuoteBidding(context, quoteId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/quotes/${quoteId}/close_quote_bidding`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deleteQuote(context, quoteId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/quotes/${quoteId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  setQuoteBidding(_, quoteId) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/quotes/${quoteId}/bidding`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getBiddingQuotes(context, params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/quotes/quotes_bidding', {
          params,
        })
        .then(response => {
          context.commit('SET_QUOTES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  getQuotesWon(context, { winnerBranch }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/quotes/quotes_won', {
          params: {
            by_winner_branch: winnerBranch,
          },
        })
        .then(response => {
          context.commit('SET_QUOTES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  fetchQuoteBidding(context, quoteId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/quotes/${quoteId}/quote_bidding`)
        .then(response => {
          context.commit('SET_QUOTE', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  fetchSupplierBranchQuoteItems(context, { quoteId = null, branchId = null }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/supplier_branch_quote_items', {
          params: {
            by_quote: quoteId,
            by_supplier_branch: branchId,
          },
        })
        .then(response => {
          context.commit('SET_SUPPLIER_QUOTE_ITEMS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  bidInQuote(_, { quoteId, supplier_branch_quote_items_records }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/quotes/${quoteId}/bid_in_quote`, {
          quote: { supplier_branch_quote_items_records },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  acceptQuote(_, { quoteId }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/quotes/${quoteId}/accept_bid`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  rejectQuote(_, { quoteId }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/quotes/${quoteId}/reject_bid`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  payQuote(_, { quoteId, attachment }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/quotes/${quoteId}/pay_quote`, {
          payment_attachment: attachment,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  approvePayment(context, { quoteId }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/quotes/${quoteId}/approve_payment`)
        .then(response => {
          resolve(response.data)
          context.commit('SET_QUOTE', response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  rejectPayment(context, { quoteId }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/quotes/${quoteId}/reject_payment`)
        .then(response => {
          resolve(response.data)
          context.commit('SET_QUOTE', response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getQuoteInovices(context, { quoteId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/quotes/${quoteId}/invoices.json`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  downloadQuoteInovices(_, quote) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/quotes/${quote.id}/invoices.zip`, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/octet-stream',
          })
          const filename = `facturas-cotizacion-folio-${quote.folio}.zip`
          saveAs(blob, filename)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  createQuoteInovice(context, { quoteId, attachments }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/v1/quotes/${quoteId}/invoices`, attachments, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deleteQuoteInovice(context, { quoteId, invoiceId }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/quotes/${quoteId}/invoices/${invoiceId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  downloadQuotes(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/quotes.zip', {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
          },
          params,
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/octet-stream',
          })
          const filename = 'facturas-cotizacions.zip'
          saveAs(blob, filename)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  deleteQuoteItems(context, { quoteId, items }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/quotes/${quoteId}/delete_quote_items`, {
          data: {
            quote: {
              quote_items_attributes: items,
            },
          },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getQuotePayments(context, { quoteId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/quotes/${quoteId}/payments`)
        .then(response => {
          context.commit('SET_QUOTE_PAYMENTS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  uploadQuotePayment(context, { quoteId, payment }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/v1/quotes/${quoteId}/payments`, { payment })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deleteQuotePayment(context, { quoteId, paymentId }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/quotes/${quoteId}/payments/${paymentId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
