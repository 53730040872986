import Vue from 'vue'
import moment from 'moment'
import { getCurrentInstance } from '@vue/composition-api'

Vue.filter('money', value => {
  const val = (value / 1).toFixed(2)
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})
Vue.filter('date', value => {
  const vm = getCurrentInstance().proxy
  if (!value) return vm.$t('notPublished')
  return moment(value).format('DD/MMM/YYYY')
})

Vue.filter('roleColor', value => {
  const roles = {
    admin: 'primary',
    customer: 'warning',
    auctioneer: 'info',
    supplier_admin: 'danger',
  }
  return roles[value]
})

Vue.filter('roleName', value => {
  const roles = {
    admin: 'Administrador',
    customer: 'Cliente',
    auctioneer: 'Subastador',
    supplier_admin: 'Admin Proveedor',
  }
  return roles[value]
})

Vue.filter('unit', value => {
  const vm = getCurrentInstance().proxy
  const unitTypeOptions = {
    meter: vm.$t('meter'),
    square_meters: vm.$t('squareMeters'),
    cubic_meters: vm.$t('cubicMeters'),
    piece: vm.$t('piece'),
    kilogram: vm.$t('kilogram'),
    ton: vm.$t('ton'),
    bundle: vm.$t('bundle'),
    box: vm.$t('box'),
    trip: vm.$t('trip'),
  }
  return unitTypeOptions[value]
})

Vue.filter('units', value => {
  const unitTypeOptions = {
    meter: 'meter',
    square_meters: 'squareMeters',
    cubic_meters: 'cubicMeters',
    piece: 'piece',
    kilogram: 'kilogram',
    ton: 'ton',
    bundle: 'bundle',
    box: 'box',
    trip: 'trip',
  }
  return unitTypeOptions[value]
})

Vue.filter('status', value => {
  const vm = getCurrentInstance().proxy
  const status = {
    pending: vm.$t('pending'),
    awaiting_review: vm.$t('awaitingReview'),
    approved: vm.$t('approved'),
    denied: vm.$t('rejected'),
  }
  return status[value]
})
Vue.filter('statusColor', value => {
  const status = {
    pending: 'secondary',
    awaiting_review: 'warning',
    approved: 'success',
    denied: 'danger',
  }
  return status[value]
})
