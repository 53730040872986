import axios from '@axios'
import Vue from 'vue'

const state = () => ({
  projects: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
  project: JSON.parse(localStorage.getItem('project')) || {
    name: 'string',
    folio: 0,
    customer_id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    active_status: true,
    address: {
      name: 'string',
      street: 'string',
      ext_number: 'string',
      int_number: 'string',
      city: 'string',
      state: 'string',
      postal_code: 'string',
      suburb: 'string',
      lat: 0,
      lng: 0,
    },
    income: [],
    expenses: [],
  },
})

const getters = {
  projects(state) {
    return state.projects.data
  },
  project(state) {
    return state.project
  },
  projectIncome(state) {
    return state.project.income
  },
  projectExpenses(state) {
    return state.project.expenses
  },
}

const mutations = {
  ADD_PROJECT(state, project) {
    state.projects.push(project)
  },
  SET_PROJECTS(state, payload) {
    localStorage.setItem('project', JSON.stringify(payload))
    state.projects = payload
  },
  CLEAR_PROJECTS(state) {
    state.projects = {
      meta: {
        pagination: {
          page: 1,
          per_page: 10,
          total_objects: 0,
        },
      },
      data: [],
    }
  },
  SET_PROJECT(state, payload) {
    localStorage.setItem('project', JSON.stringify(payload))
    Vue.set(state, 'project', payload)
  },
}

const actions = {
  fetchProjects(
    context,
    {
      customerId = null,
      status = null,
      pagination = { page: 1, per_page: 10, all: true },
    },
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/projects', {
          params: {
            by_customer: customerId,
            by_active_status: status,
            meta: {
              pagination,
            },
          },
        })
        .then(response => {
          context.commit('SET_PROJECTS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  addProject(context, project) {
    return new Promise((resolve, reject) => {
      axios
        .post('/v1/projects', {
          project,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchProject(context, projectId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/projects/${projectId}`)
        .then(response => {
          context.commit('SET_PROJECT', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  editProject(context, project) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/projects/${project.id}`, {
          project,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deleteProject(context, projectId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/projects/${projectId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
