import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import auth from './auth/auth'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import suppliers from './suppliers/suppliers'
import categories from './categories/categories'
import products from './products/products'
import pos from './pos/pos'
import branches from './branches/branches'
import projects from './projects/projects'
import quotes from './quotes/quotes'
import incomes from './incomes/incomes'
import expenses from './expenses/expenses'
import groupedExpenses from './groupedExpenses/groupedExpenses'
import shippings from './shippings/shippings'
import customers from './customers/customers'
import users from './users/users'
import settings from './settings/settings'
import purchaseOrders from './purchaseOrders/purchaseOrders'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    suppliers,
    categories,
    products,
    pos,
    branches,
    auth,
    projects,
    quotes,
    expenses,
    incomes,
    groupedExpenses,
    shippings,
    customers,
    users,
    settings,
    purchaseOrders,
  },
  state: {
    apiUrl: process.env.VUE_APP_API_URL,
  },
  strict: process.env.DEV,
})
