export default [
  {
    path: '/usuarios',
    name: 'users',
    component: () => import('@/views/users/Users.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/miembros/:userId',
    name: 'members',
    component: () => import('@/views/users/Members.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/usuarios/:id',
    name: 'scoped-users',
    component: () => import('@/views/users/Users.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/nuevo-usuario',
    name: 'new-user',
    component: () => import('@/views/users/UserNew.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/nuevo-miembro/:userId',
    name: 'new-member',
    component: () => import('@/views/users/MemberNew.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/editar-usuario/:id',
    name: 'edit-user',
    component: () => import('@/views/users/UserNew.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
