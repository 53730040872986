import axios from '@axios'
import { saveAs } from 'file-saver'

const state = () => ({
  purchaseOrders: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
  purchaseOrder: {
    address: {},
  },
})

const getters = {
  purchaseOrders(state) {
    return state.purchaseOrders.data
  },
  purchaseOrder(state) {
    return state.purchaseOrder
  },
  purchaseOrdersPagination(state) {
    return state.purchaseOrders.meta.pagination
  },
}

const mutations = {
  SET_PURCHASE_ORDERS(state, payload) {
    state.purchaseOrders = payload
  },
  SET_PURCHASE_ORDER(state, payload) {
    state.purchaseOrder = payload
  },
  CHANGE_PAGINATION(state, { field, value }) {
    state.purchaseOrders.meta.pagination[field] = value
  },
}

const actions = {
  fetchPurchaseOrders(
    context,
    {
      shippingStatus, branchId, quoteId, isInvoiced, invoicedAt, meta,
    },
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/purchase_orders', {
          params: {
            by_shipping_status: shippingStatus,
            by_supplier_branch: branchId,
            by_quote: quoteId,
            by_is_invoiced: isInvoiced,
            by_invoiced_at: invoicedAt,
            meta,
          },
        })
        .then(response => {
          context.commit('SET_PURCHASE_ORDERS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchPurchaseOrder(context, purchaseOrderId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/purchase_orders/${purchaseOrderId}`)
        .then(response => {
          context.commit('SET_PURCHASE_ORDER', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  shipPurchaseOrder(context, { purchaseOrderId, attachment }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/purchase_orders/${purchaseOrderId}/shipped`, {
          document_attachment: attachment,
        })
        .then(response => {
          context.commit('SET_PURCHASE_ORDER', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  approveShipment(context, { purchaseOrderId }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/purchase_orders/${purchaseOrderId}/approve_shipment`)
        .then(response => {
          context.commit('SET_PURCHASE_ORDER', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  rejectShipment(context, { purchaseOrderId }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/purchase_orders/${purchaseOrderId}/reject_shipment`)
        .then(response => {
          context.commit('SET_PURCHASE_ORDER', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  getPurchaseOrderInovices(context, { purchaseOrderId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/purchase_orders/${purchaseOrderId}/invoices.json`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  downloadPurchaseOrderInovices(context, purchaseOrder) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/purchase_orders/${purchaseOrder.id}/invoices.zip`, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/octet-stream',
          })
          const filename = `facturas-orden-de-compra-${purchaseOrder.supplier_branch.name}.zip`
          saveAs(blob, filename)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  createPurchaseOrderInovice(context, { purchaseOrderId, attachments }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/v1/purchase_orders/${purchaseOrderId}/invoices/`, attachments, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  deletePurchaseOrderInovice(context, { purchaseOrderId, invoiceId }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/v1/purchase_orders/${purchaseOrderId}/invoices/${invoiceId}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  downloadPurchaseOrders(_, params) {
    return new Promise((resolve, reject) => {
      axios
        .get('/v1/purchase_orders.zip', {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
          },
          params,
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/octet-stream',
          })
          const filename = 'facturas-cotizacions.zip'
          saveAs(blob, filename)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
