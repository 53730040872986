import axios from '@axios'

const state = () => ({
  users: {
    data: [],
    meta: {
      pagination: {},
    },
  },
  user: {},
  loading: false,
})

const getters = {
  users(state) {
    return state.users.data
  },
  pagination(state) {
    return state.users.meta.pagination
  },
}

const mutations = {
  loading: (state, payload) => {
    state.loading = payload
  },
  SET_USERS(state, payload) {
    state.users = payload
  },
  SET_USER(state, payload) {
    state.user = payload
  },
  CHANGE_PAGE(state, page) {
    state.users.meta.pagination.page = page
  },
}

const actions = {
  fetchUsers(context, params) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true)
      axios
        .get('/v1/users', {
          params,
        })
        .then(response => {
          context.commit('SET_USERS', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('loading', false)
        })
    })
  },

  fetchUser(context, id) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true)
      axios
        .get(`/v1/users/${id}`)
        .then(response => {
          context.commit('SET_USER', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('loading', false)
        })
    })
  },

  editUser(context, user) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true)
      axios
        .put(`/v1/users/${user.id}`, {
          user,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          context.commit('loading', false)
        })
    })
  },

  createUser(context, user) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true)
      axios
        .post('/v1/users', {
          user,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => {
          context.commit('loading', false)
        })
    })
  },
  deactivateUser(context, user) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/users/${user.id}`, {
          user: { ...user, active_status: !user.active_status },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  changeSupplierToUser(context, userId) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true)
      axios
        .post(`/v1/users/${userId}/change_user_from_supplier_to_customer`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
        .finally(() => {
          context.commit('loading', false)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
