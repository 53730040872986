export default [
  {
    path: '/proveedores',
    name: 'suppliers',
    component: () => import('@/views/suppliers/Suppliers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/registrar-proveedor',
    name: 'registerSupplier',
    component: () => import('@/views/suppliers/RegisterSupplier.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/registrarme-como-proveedor',
  //   name: 'registerSupplierWithoutAccount',
  //   component: () => import('@/views/suppliers/RegisterSupplier.vue'),
  //   meta: {
  //     layout: 'full',
  //     redirectIfLoggedIn: true,
  //     requiresAuth: false,
  //   },
  // },
  {
    path: '/editar-provedor/:supplierId',
    name: 'editSupplier',
    component: () => import('@/views/suppliers/EditSupplier.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/proveedor/:id',
    name: 'branches',
    component: () => import('@/views/branches/Branches.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/proveedor/cotizaciones/:id',
    name: 'supplierQuotes',
    component: () => import('@/views/pricing/SupplierQuotes.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/proveedor/ventas/:id',
    name: 'supplierSells',
    component: () => import('@/views/pricing/SupplierSells.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/solicitudes',
    name: 'suppliersRequest',
    component: () => import('@/views/suppliers/Suppliers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
