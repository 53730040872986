/* eslint-disable no-console */
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
          + 'For more details, visit https://goo.gl/AFskqB',
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      // console.log('New content is available; please refresh.')
      const vm = new Vue()
      vm.$toast({
        component: ToastificationContent,
        props: {
          title: 'Nuevo Contenido descargado. Se Actualizara la pagina',
          icon: 'AlertCircleIcon',
          variant: 'success',
        },
      })
      setTimeout(() => {
        window.location.reload(true)
      }, 3500)
    },
    offline() {
      const vm = new Vue()
      vm.$toast({
        component: ToastificationContent,
        props: {
          title: 'No internet connection found. App is running in offline mode.',
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
