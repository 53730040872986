export default [
  {
    path: '/mis-productos',
    name: 'productsCatalogs',
    component: () => import('@/views/products/Products.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/productos/:type/:id',
    name: 'supplierProductsCatalogs',
    component: () => import('@/views/products/Products.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/registrar-producto',
    name: 'registerProduct',
    component: () => import('@/views/products/RegisterProduct.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/editar-producto/:productId',
    name: 'editProduct',
    component: () => import('@/views/products/EditProduct.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/registrar-producto/:type/:id',
    name: 'registerSupplierProduct',
    component: () => import('@/views/pos/POS.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
