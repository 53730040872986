import axios from '@axios'

const state = () => ({
  branches: {
    meta: {
      pagination: {
        page: 1,
        per_page: 10,
        total_objects: 0,
      },
    },
    data: [],
  },
  branch: {},
})

const getters = {
  branches(state) {
    return state.branches.data
  },
  branch(state) {
    return state.branch
  },
}

const mutations = {
  SET_BRANCHES(state, payload) {
    state.branches = payload
  },
  SET_BRANCH(state, payload) {
    state.branch = payload
  },
}

const actions = {
  fetchBranches(context, { supplierId, params = null }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/suppliers/${supplierId}/supplier_branches`, {
          params,
        })
        .then(response => {
          context.commit('SET_BRANCHES', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  addBranch(context, { supplierId, supplierBranch }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/v1/suppliers/${supplierId}/supplier_branches`, {
          supplier_branch: supplierBranch,
        })
        .then(response => {
          context.dispatch('fetchCategories')
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  fetchBranch(context, { supplierId, branchId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v1/suppliers/${supplierId}/supplier_branches/${branchId}`)
        .then(response => {
          context.commit('SET_BRANCH', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  editBranch(context, { branch, branchId, supplierId }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/v1/suppliers/${supplierId}/supplier_branches/${branchId}`, {
          supplier_branch: branch,
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  setBranchExpress(context, { branchId, supplierId, is_express }) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `/v1/suppliers/${supplierId}/supplier_branches/${branchId}/change_is_express`,
          { supplier_branch: { is_express } },
        )
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
