export default [
  {
    path: '/ordenes-de-compra/:quoteId/remisiones',
    name: 'remissions',
    component: () => import('@/views/purchaseOrders/Remissions.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ordenes-de-compra/:quoteId',
    name: 'purchaseOrders',
    component: () => import('@/views/invoices/PurchaseOrdersInvoices.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/orden-de-compra/:quoteId',
    name: 'purchaseOrder',
    component: () => import('@/views/purchaseOrders/PurchaseOrders.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/orden-de-compra/:quoteId/facturas',
    name: 'purchaseOrderInvoices',
    component: () => import('@/views/invoices/Invoices.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ordenes-de-compra/remision/:purchaseOrderId',
    name: 'uploadRemission',
    component: () => import('@/views/purchaseOrders/Remission.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
